import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import router from '@/router/index'


const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? 'http://127.0.0.6:8083' : 'http://workm.zcar.cn/',
    timeout: 60 * 1000,

})

instance.interceptors.request.use((config) => {
    if (config.url !== 'e/login' && config.url !== 'e/create_code') {
        if (!localStorage.getItem('token')) {
            ElMessage('你还没有登录，请登录后访问')
            router.replace('/login')
        } else {
            config.headers['Authorization'] = localStorage.getItem('token')
        }
    }


    // 请求之前 做的事情
    return config;
}, (error) => {
    // 对请求错误做些什么
    console.log(error)
    return Promise.reject(error);
})

instance.interceptors.response.use((response) => {

    return response;
}, (error) => {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
})

export default instance;