import request from "@/ulits/request";

// 新增表单
export function addTableBasicsInfoRequest(data) {
    return request.post('e/addTableBasicsInfo', data)
}


// 新增表单
export function selectTableListRequest(data) {
    return request.post('e/selectTableList', data)
}

// 保存数据
export function saveTableRequest(data) {
    return request.post('e/saveTable', data)
}


// 查询单个表的数据
export function select_data_tableRequest(data) {
    return request.post('e/select_data_table', data)
}


// 保存并发布
export function issueTableRequest(data) {
    return request.post('e/issueTable', data)
}


// 发送通知消息给 专员
export function inform_adminRequest(data) {
    return request.post('e/inform_admin', data)
}



