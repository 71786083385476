import request from "@/ulits/request";

export function loginRequest(data) {
    return request.post('e/login', data)
}

// 发送验证码
export function sendCodeReuest(data) {
    return request.post('e/create_code', data)
}


// 注册信息
export function registerReuest(data) {
    return request.post('e/register', data)
}

// 根据 userid 查询 专员信息
export function userid_queryReuest(data) {
    return request.post('e/userid_query', data)
}

// 验证是否登录
export function v_loginReuest(data) {
    return request.post('e/v_login', data)
}


