<template>
    <router-view/>
</template>

<script setup>

</script>

<style>
body {
    margin: 0;
    padding: 0;
}
</style>
