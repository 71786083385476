import {createRouter, createWebHashHistory} from "vue-router";
import {selectTableListRequest} from "@/api";
import {ElMessage} from "element-plus";
import {v_loginReuest} from "@/api/login";

const routes = [
    {
        path: '/',
        component: () => import("../views/index"),
        meta: {title: '自采数据'}
    }, {
        path: '/login',
        name: 'login',
        component: () => import("../views/login"),
        meta: {title: '登录'}
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({y: 0}),
    routes: routes
})

// 前置
router.beforeEach((to, from, next) => {
    if (to.path !== '/login') {
        v_loginReuest().then(res => {
            let {code} = res.data
            code !== 200 ? next('/login') : next()
        })
    } else {
        next()
    }
})

// 后置
router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
})

export default router